<!--
    Created by 王丽莎 on 2024/08/29.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：运营管理-充电桩分账模版-模版下发记录-查看模版
-->
<style lang="less">
#separate-accounts-check-template {

}
</style>

<template>
  <yg-modal
    width="580"
    title="分账模版"
    :show-bottom="false"
    @on-sure="_sure"
    @on-close="_close"
  >
    <div
      id="separate-accounts-check-template"
      slot="content"
      style="min-height: 500px"
    >
      <v-detail
        :is-edit="false"
        :model="model"
      />
    </div>
  </yg-modal>
</template>

<script>
import vDetail from './submit/detail.vue';
export default {
  components: { vDetail },
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
    };
  },
  created () {
  },
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    _sure () {
      this.$emit('on-sure');
    },
    _close () {
      this.$emit('on-close');
    }
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>

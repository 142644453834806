<!--
    Created by 程雨喵'mac on 2024/9/2.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：提交分账模版(新增+复制)
-->
<style lang="less">
.separate-temp-add {
  padding-bottom: @containerGap;
}
</style>

<template>
  <yg-modal
    title="新增模板"
    width="500"
    :sure-button-disabled="buttonDisabled"
    :sure-button-disabled-text="buttonDisabledMsg"
    @on-sure="onSure"
    @on-close="onClose"
  >
    <div
      slot="content"
      class="separate-temp-add"
    >
      <Alert
        v-if="copyTitle"
        style="margin-bottom: 20px"
      >
        当前模板复制于：
        <template slot="desc">
          {{ copyTitle }}
        </template>
      </Alert>
      <v-detail
        ref="templateDetail"
        :is-edit="true"
        :model="model"
        @on-change="onChangeDetail"
      />
    </div>
  </yg-modal>
</template>

<script>
import OperationsApiSet from '@/request/api/OperationsApiSet';
import vDetail from './detail.vue';
export default {
  components: { vDetail },
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      title: '新增模板',
      copyTitle: '',
      buttonDisabled: true,
      buttonDisabledMsg: ''
    };
  },
  created () {
    if (this.model.name) {
      this.copyTitle = this.model.name;
      this.model.name = '';
    }
  },
  methods: {
    onSure () {
      const { success, msg, params } = this.$refs.templateDetail.$checkSubmitDataValid();
      if (success) {
        this.requestAdd(params);
      } else if (msg) {
        this.$Message.warning(msg);
      }
    },
    onClose () {
      this.$emit('on-close', false);
    },
    onChangeDetail ({ disabled, msg }) {
      this.buttonDisabled = disabled;
      this.buttonDisabledMsg = msg;
    },
    requestAdd (params) {
      const api = OperationsApiSet.addAllocateTemplate;
      api.params = params;
      this.$http(api).then(res => {
        this.$Message.success('操作成功');
        this.$emit('on-close', true);
      });
    }
  }
};
</script>

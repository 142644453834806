import BaseModel from '@/libs/base/dataModel/BaseModel';
import DeviceRatioModel from '@/pages/050__deviceManager/chargingPileList/model/DeviceRatioModel';

export class SeparateAccountsListModel extends BaseModel {
  constructor () {
    super();
    // 模板id
    this.templateId = '';
    // 模板名称
    this.name = '';
    // 分账方式
    this.allocateType = '';
    // 分账方式描述
    this.allocateTypeDesc = '';
    // 模板创建时间
    this.createTime = '';
    // 模板创建人(姓名+手机号)
    this.createUser = '';
  }

  initData (resData) {
    super.initData(resData);
    return this;
  }

  static createFakeDataList (len = 30) {
    const list = [];
    const saTypeList = ['按功率分账'];
    for (let i = 0; i < len; i++) {
      const dic = {
        name: BaseModel.getFakeTitle(Math.ceil(Math.random() * 10)),
        templateId: '000101010' + i,
        allocateType: i % 1,
        allocateTypeDesc: saTypeList[i % 1],
        createTime: '2024-08-2' + i,
        createUser: BaseModel.getFakeTitle(Math.ceil(Math.random() * 5))
      };
      list.push(dic);
    }
    return list;
  }
}

export class SeparateAccountsDetailModel extends DeviceRatioModel {
  constructor () {
    super();
    // 模板id
    this.templateId = '';
    // 模板名称
    this.name = '';
    // 分账方式
    this.allocateType = 1;
    // 分账方式描述
    this.allocateTypeDesc = '';
  }

  initData (resData) {
    super.initData(resData);
    return this;
  }

  /**
   * 新增校验
   * @returns {*}
   */
  checkValidAddSubmit () {
    if (this.name.length > 36) {
      return BaseModel.getValidRes(false, '模板名称最多支持36位');
    }
    return BaseModel.getValidRes(true, '', {
      name: this.name,
      allocateType: this.allocateType,
      propertyCompanyRatio: parseInt(this.h5PropertyCompanyRatio * 100),
      serviceProviderRatio: parseInt(this.h5ServiceProviderRatio * 100),
      chargeCoinPCRatio: parseInt(this.h5ChargeCoinPCRatio * 100),
      chargeCoinSPRatio: parseInt(this.h5ChargeCoinSPRatio * 100),
      agentsRatio: this.agentsRatio,
      chargeCoinARatio: this.chargeCoinARatio
    });
  }

  static createFakeData () {
    return {
      name: BaseModel.getFakeTitle(20),
      allocateType: 1,
      propertyCompanyRatio: BaseModel.getFakeNumber(9000, 0) + 1000,
      serviceProviderRatio: BaseModel.getFakeNumber(1000, 0) + 1000,
      chargeCoinPCRatio: BaseModel.getFakeNumber(1000, 0) + 1000,
      chargeCoinSPRatio: BaseModel.getFakeNumber(9000, 0) + 1000,
      agentsRatio: 0,
      chargeCoinARatio: 0
    };
  }

  static createAdditionModel () {
    return new SeparateAccountsDetailModel();
  }
}

export class SeparateAccountsRecordListModel extends BaseModel {
  constructor () {
    super();
    // 下发记录id
    this.recordId = '';
    // 模版ID
    this.templateId = '';
    // 模板名称
    this.templateName = '';
    // 下发日期
    this.createTime = '';
    // 小区总数
    this.communityNum = '';
    // 充电桩总数
    this.deviceNum = '';
    // 下发操作人
    this.createUser = '';
    // 下发状态（0-待处理 1-处理中 2-处理完成）
    this.status = '';
    // 下发状态描述
    this.statusDesc = '';
    // 下发充电桩结果-成功数量
    this.succNum = '';
    // 下发充电桩结果-失败数量
    this.failNum = '';
    // 前端专用 ==========>
    // 页面中展示的成功条数
    this.h5SuccessAmountStr = '';
    // 页面中展示的失败条数
    this.h5ErrorAmountStr = '';
    // 前端专用 ==========>
  }

  initData (resData) {
    super.initData(resData);
    switch (this.status) {
    case 2:
      this.h5SuccessAmountStr = this.succNum + '条';
      this.h5ErrorAmountStr = this.failNum + '条';
      break;
    default:
      this.h5SuccessAmountStr = '-';
      this.h5ErrorAmountStr = '-';
      break;
    }
    return this;
  }

  static createFakeDataList (len = 30) {
    const list = [];
    const sendStatusList = ['待处理', '处理中', '处理完成'];
    for (let i = 0; i < len; i++) {
      const dic = {
        templateName: BaseModel.getFakeTitle(Math.ceil(Math.random() * 10)),
        templateId: '000101010' + i,
        status: i % 3,
        statusDesc: sendStatusList[i % 3],
        createTime: '2024-08-2' + i,
        createUser: BaseModel.getFakeTitle(Math.ceil(Math.random() * 5)),
        communityNum: BaseModel.getFakeNumber(Math.ceil(Math.random() * 500), 0),
        deviceNum: BaseModel.getFakeNumber(Math.ceil(Math.random() * 500), 0),
        succNum: BaseModel.getFakeNumber(Math.ceil(Math.random() * 500), 0),
        failNum: BaseModel.getFakeNumber(Math.ceil(Math.random() * 500), 0)
      };
      list.push(dic);
    }
    return list;
  }
}
